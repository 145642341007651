<template>
    <div class="content-main">
        <div class="nav-contain">
            <ul class="nav">
                <li v-for="(item, index) in navs" :key="index" @click="routeGo(item.path)">
                    <span>{{ item.title }}</span>
                </li>
            </ul>
        </div>
        <img class="img" src="../assets/douyin.png" />
    </div>
</template>

<script>
import { ref } from 'vue';
import router from '../router/index';
export default {
    setup() {
        const navs = ref([
            { title: '整合营销', path: '/service-case/case1' },
            { title: '游戏互动', path: '/service-case/case2' },
            { title: '物料设计', path: '/service-case/case3' },
            { title: '新媒体代运营', path: '/service-case/case4' },
            { title: '短视频拍摄&剪辑', path: '/service-case/case5' },
            { title: '产品拍摄', path: '/service-case/case6' },
            { title: '网站和UI设计', path: '/service-case/case7' },
            { title: '直播代运营', path: '/service-case/case8' },
            { title: '媒介采买', path: '/service-case/case9' },
        ]);

        const routeGo = (path) => {
            router.push(path)
        }

        return {
            navs, routeGo
        }
    }
}
</script>

<style scoped>
.content-main {
    width: 1200px;
    height: 633px;
    background: url("../assets/fuwuanli-bg.png") no-repeat center center;
    background-size: 100% auto;
    position: relative;
}
.nav-contain {
    position: absolute;
    width: 850px;
    height: 100%;
    left: 40px;
    top: 0px;
    background: url("../assets/anli/bg.png") no-repeat center center;
    background-size: 100% auto;
    background-size: contain;
    z-index: 2;
    opacity: 0.96;
}
.nav {
    position: absolute;
    top: 160px;
    left: 60px;
    width: 480px;
}
.nav li {
    width: 120px;
    height: 50px;
    background: url("../assets/anli/nav-bg.png") no-repeat center center;
    background-size: 100% 100%;
    text-align: center;
    line-height: 50px;
    float: left;
    margin: 8px 10px;
    cursor: pointer;
}
.nav li:hover {
    opacity: 1;
}
.nav li span {
    font-size: 12px;
    color: #fff;
    text-decoration: none;
    font-weight: bold;
}
.nav li span:hover {
    color: purple;
}
.content-main img {
    position: absolute;
    width: 300px;
    right: 80px;
    top: 80px;
    cursor: pointer;
}
</style>